<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-center">
					<div class="card bgGreen border-0 rls">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21">Saldo de combustible</div>
								<div class="font-size-14">
									<div class="row">
										<div class="col-md-12 text-center">
											{{ numeral(totals.in - totals.out).format('$0,0.00') }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<!-- <div class="col-md-4">
					<b>Rango de Fecha </b>
					<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
					<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
				</div> -->
				<div class="col-md-12 text-right">
					<!-- <a-button class="btn btn-info" @click="openModal('fund')">Fondeo</a-button> -->
					<a-button class="btn btn-purple ml7" @click="openModal('allocate')">Asignación</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
				<div class="col-md-12 pt10">
					<a-table :columns="columns" :dataSource="fuelControlList" rowKey="id">
						<div slot="id" slot-scope="record">
							{{ record.id }}
						</div>
						<div slot="created_at" slot-scope="record">
							<div>{{ record.issuer }}</div>
							<div>{{ record.created_at }} hrs.</div>
						</div>
						<div slot="ticket" slot-scope="record">
							<div v-if="record.type == 'out'">
								<div>{{ record.quantity }}</div>
								<div>{{ record.ticket }}</div>
							</div>
							<div v-else>---</div>
						</div>
						<div slot="details" slot-scope="record">
							<div>{{ record.folio }}</div>
							<div>{{ numeral(record.amount).format('$0,0.00') }}</div>
							<div>
								<a-tag :color="record.type == 'in' ? 'blue' : 'purple'">{{ record.type == 'in' ? 'Fondeo' : 'Asignación' }}</a-tag>
							</div>
						</div>
						<div slot="vehicle" slot-scope="record">
							<div v-if="record.type == 'out'">
								{{ record.receiver }}<br />
								{{ record.vehicle }}<br />
								<!-- {{ numeral(record.odometer).format('0,0') }} Km -->
							</div>
							<div v-else>---</div>
						</div>
						<div slot="action" slot-scope="record">
							<div v-if="record.type == 'in'">
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only" @click="getSecureURL(record.id)">
									<a-icon type="file" />
								</button>
							</div>
							<div v-if="record.type == 'out'">
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only" v-if="hasFile(record.id, 'fuelAllocation_1')" @click="getSecureURL(record.id)">
									<a-icon type="file" />
								</button>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" v-if="hasFile(record.id, 'fuelAllocation_2')" @click="getSecureURL(record.id)">
									<a-icon type="file" />
								</button>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" v-if="hasFile(record.id, 'fuelAllocation_3')" @click="getSecureURL(record.id)">
									<a-icon type="file" />
								</button>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modal.file.visible" title="Comprobante" :closable="false" width="80%" v-if="modal.file.visible">
			<template slot="footer">
				<a-button key="back" @click="closeModal('file')"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<fileViewerComponent :fileURL="base64" />
			</div>
		</a-modal>
		<a-modal width="50%" :visible="modal.fund.visible" title="Ingreso de Combustible" :closable="true" @cancel="closeModal('fund')">
			<template slot="footer">
				<a-button key="back" @click="closeModal('fund')" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="fuelAllocation('in')" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<modalFuelOperation ref="modalFuelOperation" :visible="modal.fund.visible" @ok="initModule" @close="closeModal('fund')" />
		</a-modal>
		<a-modal width="65%" :visible="modal.allocate.visible" title="Asignación de Combustible" :closable="true" @cancel="closeModal('allocate')">
			<template slot="footer">
				<a-button key="back" @click="closeModal('allocate')" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="fuelAllocation('out')" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<modalFuelAllocation ref="modalFuelAllocation" :visible="modal.allocate.visible" @ok="initModule" @close="closeModal('allocate')" />
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import modalFuelOperation from '@/components/modalFuelOperation'
import modalFuelAllocation from '@/components/modalFuelAllocation'
import fileViewerComponent from '@/components/fileViewer'
import Swal from 'sweetalert2'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'cashFlowView',
	components: {
		modalFuelOperation,
		fileViewerComponent,
		modalFuelAllocation,
	},
	data() {
		return {
			modal: {
				file: {
					visible: false,
				},
				fund: {
					visible: false,
				},
				allocate: {
					visible: false,
				},
			},
			base64: '',
			columns: [
				{
					title: 'ID',
					align: 'center',
					scopedSlots: { customRender: 'id' },
					width: '5%',
				},
				{
					title: 'Solicitante/Fecha',
					align: 'center',
					scopedSlots: { customRender: 'created_at' },
					width: '20%',
				},
				{
					title: 'Recibe/Vehículo',
					align: 'center',
					scopedSlots: { customRender: 'vehicle' },
					width: '20%',
				},
				{
					title: 'Folio/Monto',
					align: 'center',
					scopedSlots: { customRender: 'details' },
					width: '20%',
				},
				{
					title: 'Litros/Ticket',
					align: 'center',
					scopedSlots: { customRender: 'ticket' },
					width: '20%',
				},
				// {
				// 	title: 'Archivos',
				// 	scopedSlots: { customRender: 'action1' },
				// 	align: 'right',
				// 	width: '20%',
				// },
			],
			dateFormat,
			totals: {
				in: 0,
				out: 0,
			},
		}
	},
	computed: {
		...mapGetters('fuelControl', ['filters', 'fuelControlList', 'spinnerLoader', 'spinnerLoaderLabel']),
	},
	mounted() {
		if (!this.fuelControlList.length) {
			this.initModule()
		}
	},
	methods: {
		moment,
		numeral,
		hasFile(id, type) {
			return !!this.fuelControlList.find((e) => e.id == id).files.find((e) => e.type == type)
		},
		fuelAllocation(type) {
			// console.log('fuelAllocation-->', type)
			if (type == 'in') {
				this.$refs.modalFuelOperation.onSaveRecord()
			}
			if (type == 'out') {
				this.$refs.modalFuelAllocation.onSaveRecord()
			}
		},
		initModule(date, dateString) {
			//
			let payload = {}

			if (!!dateString && dateString.length) {
				payload = {
					startDate: dateString[0],
					endDate: dateString[1],
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
					endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
				}
			}
			this.$store.dispatch('fuelControl/GET', payload).then((response) => {
				this.totals = {
					in: response.data.filter((e) => e.type == 'in').reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
					out: response.data.filter((e) => e.type == 'out').reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
				}
				// console.log('fuelControl/GET::response-->', response)
			})
		},
		getSecureURL(id) {
			this.$store.dispatch('cashFlow/GET_FILE_URL', id).then((response) => {
				this.openModal('file')
				this.base64 = response.data.url
			})
		},
		openModal(type) {
			if (type == 'allocate') {
				//validar si aún hay saldo
				if (this.totals.in - this.totals.out == 0) {
					Swal.fire({
						title: 'Asignación de Combustible',
						text: 'No se cuenta con saldo para asignar combustible',
					})
					return false
				}
			}
			this.modal[type].visible = true
		},
		closeModal(type) {
			this.modal[type].visible = false
			setTimeout(() => {
				this.base64 = ''
			}, 1000)
		},
		onResetFilter(key) {
			this.$store.dispatch('fuelControl/RESET_ONE_FILTER', key)
		},
	},
}
</script>
<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-4">
				<b>Recibe</b>
				<a-select v-model="payload.receiver" style="width: 100%">
					<a-select-option v-for="(user, index) in usersList.filter((e) => e.allow_fuel_operations)" :key="index" :value="user.user_id">{{ user.fullName }}</a-select-option>
				</a-select>
			</div>
			<div class="col-md-4">
				<b>Unidad</b>
				<a-select v-model="payload.vehicle" style="width: 100%">
					<a-select-option value="Bestia 1">Bestia 1</a-select-option>
					<a-select-option value="Bestia 2">Bestia 2</a-select-option>
				</a-select>
			</div>
			<div class="col-md-4">
				<b>Folio</b>
				<a-input autocomplete="off" v-model="payload.folio" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-4" v-if="false">
				<b>Odómetro</b>
				<a-input autocomplete="off" suffix="Km" v-model="payload.odometer" />
			</div>
			<div class="col-md-4">
				<b>Monto</b>
				<a-input autocomplete="off" prefix="$" v-money="money" v-model="payload.amount" disabled />
			</div>
			<div class="col-md-4">
				<b>Litros</b>
				<a-input autocomplete="off" v-money="gas" v-model="payload.quantity" />
			</div>
			<div class="col-md-4">
				<b>Ticket</b>
				<a-input autocomplete="off" v-model="payload.ticket" />
			</div>
		</div>
		<div class="row pt10" v-if="false">
			<div class="col-md-4">
				<b>Nota de combustible</b>
				<br />
				<a-upload name="file_0" accept="application/pdf,image/*" :file-list="file_0" :remove="handleRemovePayment" :before-upload="beforeUploadPayment_0">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
			</div>
			<div class="col-md-4">
				<b>Fotografía de bomba</b>
				<br />
				<a-upload name="file_1" accept="application/pdf,image/*" :file-list="file_1" :remove="handleRemovePayment" :before-upload="beforeUploadPayment_1">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
			</div>
			<div class="col-md-4">
				<b>Fotografía de odómetro</b>
				<br />
				<a-upload name="file_2" accept="application/pdf,image/*" :file-list="file_2" :remove="handleRemovePayment" :before-upload="beforeUploadPayment_2">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
//
export default {
	name: 'modalCashflowComponent',
	directives: {
		money: VMoney,
	},
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('cashFlow', ['spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('users', ['usersList']),
	},
	data() {
		return {
			amount: 0,
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			gas: {
				decimal: '.',
				thousands: ',',
				precision: 3,
			},
			file_0: [],
			file_1: [],
			file_2: [],
			payload: {
				type: 'out',
				receiver: '',
				vehicle: '',
				folio: '',
				odometer: '',
				amount: '625.00',
				quantity: '',
				ticket: '',
			},
		}
	},
	mounted() {
		if (this.usersList.length == 0) {
			this.$store.dispatch('users/GET')
		}
	},
	methods: {
		handleRemovePayment() {
			return false
		},
		beforeUploadPayment_0(file) {
			this.file_0 = [file]
			return false
		},
		beforeUploadPayment_1(file) {
			this.file_1 = [file]
			return false
		},
		beforeUploadPayment_2(file) {
			this.file_2 = [file]
			return false
		},
		async onSaveRecord() {
			//
			let errors = []

			// validamos quien recibe
			if (this.payload.receiver == '') {
				errors.push(`- Ingresa quien recibe`)
			}

			// validamos la unidad asociada
			if (this.payload.vehicle == '') {
				errors.push(`- Ingresa la unidad asociada`)
			}

			// validamos el folio de la nota
			if (this.payload.folio == '') {
				errors.push(`- Ingresa el folio de la nota`)
			}

			// validamos el valor del odómetro
			// if (this.payload.odometer == '') {
			// 	errors.push(`- Ingresa el valor del odómetro`)
			// }

			// validamos el monto de asignación
			// if (numeral(this.payload.amount).value() == 0) {
			// 	errors.push(`- Ingresa el monto de asignación`)
			// }

			// validamos la cantidad de combustible
			if (numeral(this.payload.quantity).value() == 0) {
				errors.push(`- Ingresa la cantidad de combustible`)
			}

			// validamos el ticket entregado
			if (this.payload.ticket == '') {
				errors.push(`- Ingresa ticket entregado en gasolinera`)
			}

			// validamos el archivo - Nota de combustible
			// if (this.file_0.length == 0) {
			// 	errors.push(`- Debes adjuntar nota de combustible`)
			// }

			// validamos el archivo - Fotografía de bomba
			// if (this.file_1.length == 0) {
			// 	errors.push(`- Debes adjuntar fotografía de bomba`)
			// }

			// validamos el archivo - Fotografía de odómetro
			// if (this.file_2.length == 0) {
			// 	errors.push(`- Debes adjuntar fotografía de odómetro`)
			// }

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			} else {
				//
				this.$store.dispatch('fuelControl/FUEL_ALLOCATION', this.payload).then(async (response) => {
					await Swal.fire({
						title: 'Operación',
						text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					})
					// subimos comprobante
					// if (this.file.length) {
					// 	let formData = new FormData()
					// 	formData.append('file', this.file[0])
					// 	formData.append('id', response.data.fuel_control_id)
					// 	formData.append('filetype', 'fuelAllocation')
					// 	await this.$store.dispatch('cashFlow/UPLOAD_FILE', formData)
					// }
					this.$emit('ok')
					this.$emit('close')
				})
			}
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.amount = numeral(0).format('0,0')
				this.file_0 = []
				this.file_1 = []
				this.file_2 = []
			}
		},
	},
}
</script>
